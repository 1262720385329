import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { getClientOptions } from '../selectors/dropdowns';

import { closePopup, createSpinnerPopup } from '../actions/popup';
import { createShop } from '../actions/shop';
import { createLoadClientList, LOAD_CLIENT_LIST_SUCCESS } from '../actions/client';

import Form from './Form';
import LabelledInput from './LabelledInput';
import Select from './Select';

class CreateShopPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shop_name: '',
      shop_type: props.type === 'company' || props.type === 'popup' ? 'single_client' : 'multiple_client',
      aggregate: props.type === 'popup' ? 1 : 0,
      loaded: !!props.clients.length
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCreateShop = this.onCreateShop.bind(this);
  }

  componentDidMount() {
    if (!this.state.loaded) {
      this.props.onLoadClients().then(action => {
        if (action.type === LOAD_CLIENT_LIST_SUCCESS) {
          this.setState({ loaded: true });
        }
      });
    }
  }

  onFieldChange(value, field) {
    switch (field) {
      case 'shop_name':
        this.setState({ shop_name: value });
        break;
      case 'shop_type':
        this.setState(state => ({ shop_type: value, client_id: 'multiple_client' === value ? null: state.client_id }));
        break;
      case 'client_id':
        this.setState({ client_id: value });
        break;
    }
  }

  onCreateShop(values, errors) {
    const { onCreateShop, redirect } = this.props;
    if (!Object.values(errors).every(e => e === null)) {
      return;
    }
    onCreateShop(values.shop_name, values.client_id, this.state.aggregate, redirect);
  }

  renderLoading() {
    const { index } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row">
         <div className="small-4 columns">
           <img src="/images/gears.gif" />
         </div>
         <div className="small-8 columns">
           Loading...
         </div>
        </div>
      </div>
    );
  }

  renderClientRequired() {
    const { index, type, onClosePopup } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row">
          <a onClick={onClosePopup} style={{ float: 'right', fontSize: '26px' }}>×</a>
        </div>
        <div className="row" style={{ marginBottom: '20px', textAlign: 'center', fontSize: '26px', padding: '100px' }}>
          <div className="small-12 columns">
             You need to add some clients before you can create a {type === 'company' ? 'Company' : (type === 'popup' ? 'Pop Up' : '')} Shop
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { type, clients, index, onClosePopup } = this.props;
    const { shop_name, shop_type, aggregate, client_id, loaded } = this.state;

    if (!loaded) {
      return this.renderLoading();
    }
    if (clients.length === 0 && 'single_client' === shop_type) {
      return this.renderClientRequired();
    }

    const handleClickCreateShop = e => {
      e.preventDefault();
      this._form.submit();
    };

    const shop_types = [
      { key: 'single_client', value: 'Shop for a Single Client' },
      { key: 'multiple_client', value: 'Shop for Multiple Clients' }
    ];

    const aggregate_types = [
      { key: '0', value: 'Non-aggregate SO' },
      { key: '1', value: 'Aggregate SO' },
    ];

    return (
      <div className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index + 1, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Create Shop</h3>
          <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }} onClick={onClosePopup}>Cancel</a>
          <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={handleClickCreateShop}>Create</a>
        </div>
        <Form
          className="row small-12 columns form"
          ref={ref => this._form = ref}
          onFieldChange={this.onFieldChange}
          onSubmit={this.onCreateShop}
        >
          <Form.TextInput field="shop_name" containerClassName="row collapse" labelClassName="small-12 columns" controlClassName="small-12 columns" required={true} value={shop_name} placeholder="New Shop Name" />
          {'single_client' === shop_type && <Form.Select field="client_id" label="Client" displayLabel={false} value={client_id} containerClassName="row collapse" controlClassName="small-12 columns" options={clients} placeholder="Select Client" disabled={'single_client' !== shop_type} searchable={true} required={'single_client' === shop_type} />}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  clients: getClientOptions(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onCreateShop: (shop_name, client_id, aggregate, redirect) => {
    dispatch(createSpinnerPopup('Creating shop...'));
     return dispatch(createShop(shop_name, client_id, aggregate, redirect)).catch(
      () => dispatch(closePopup())
    );
  },
  onLoadClients: () => dispatch(createLoadClientList())
});

const ConnectedCreateShopPopup = connect(mapStateToProps, mapDispatchToProps)(CreateShopPopup);
export default ConnectedCreateShopPopup;

